
const masterplanMap = (function(){


  const initMap2 = () =>{

    const mapElement = document.getElementById('masterplanMap');
    
    if(mapElement != null ){ 

        const markersElement = document.querySelector('.marker');
        const mapZoom = markersElement.getAttribute('data-zoom');
        const markerLat = markersElement.getAttribute('data-lat');
        const markerLng = markersElement.getAttribute('data-lng');
        const markerPos = new google.maps.LatLng(markerLat, markerLng);
    

        const map = new google.maps.Map(document.getElementById("masterplanMap"), { 
            zoom: Number(mapZoom),
            center: markerPos, 
            disableDefaultUI: true,
            styles:
          [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
        
                {
                    "color": "#1D1D16"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
                
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#7c7d5d"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#7c7d5d"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#1E1F18"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
                 
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#5B5C45"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#5B5C45"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#6C6C51"
                }
            ]
        }
    ],
        }); 


    const marker = new google.maps.Marker({ 
          position: markerPos,
          map: map,
          icon : "/images/map-marker.svg" 
        });
    }

  };
  return {
   initMap2: initMap2 
 }

})();

export { masterplanMap }; 





