

const hideShow = (function () {

	const init = () => { 

		const groundLevel = document.getElementById("groundlevel");
		const levelOne = document.getElementById("levelone");
		const groundContainer = document.querySelectorAll(".ground-level");
		const oneContainer = document.querySelectorAll(".level-one");


		if(groundLevel != null ){

			groundLevel.addEventListener("click",function(e){
				this.classList.add("active");
				levelOne.classList.remove("active");

			    groundContainer.forEach((item) => {
				    item.classList.add("opacity-100");
				    item.classList.remove("opacity-0");
				  })
			    oneContainer.forEach((item) => {
				    item.classList.add("opacity-0");
				    item.classList.remove("opacity-100");
				})
		
	 
			}); 
			
		}

		if(levelOne != null ){

			levelOne.addEventListener("click",function(e){
				this.classList.add("active");
				groundLevel.classList.remove("active");

				oneContainer.forEach((item) => {
				    item.classList.add("opacity-100");
				    item.classList.remove("opacity-0");
				  })
			    groundContainer.forEach((item) => {
				    item.classList.add("opacity-0");
				    item.classList.remove("opacity-100");
				})

			}); 
			
		}
	};


	return{
		init: init
	}

})();

export { hideShow };
