

<template>

	<div class="vue-modal-wrapper">
		<button @click="open = true" class="btn btn--primary w-full">{{ buttonText }}</button> 

		<transition name="slide-in"> 


			<div class="modal" v-if="open" >
				<div class="overflow-hidden modal-inner h-full">
					<div class="relative h-full">
						<div class="font-fkscreamer text-40 w-full flex justify-between items-center uppercase leading-66  md:text-60">
							Register your interest
						</div>

						<div class="h4 mt-30 uppercase">
							{{ title }}
						</div>

						<div class="flex flex-col justify-between md:h-per65">

							<div class="mt-15">
								<form class="register-form" ref="registerForm" method="post">
									<input type="hidden" name="action" value="cm-lists/subscribe" />
									<input type="hidden" name="redirect" :value="buttonLink" />
									<input type="hidden" name="listId" :value="listId" />
									<input type="hidden" name="CRAFT_CSRF_TOKEN" :value="csrf">
									<input type="hidden" id="id" name="id" :value="title">
									<input type="hidden" name="fields[InterestedLot]" :value="title">
									<div class="form-group form-group--styled relative  text-14 leading-14 body-text">
										<label for="fname" class="text-14 leading-14 body-text absolute top-0 transform translate-y-sm left-0 text-black">Name</label>
										<input type="text" id="fname" name="fullname">
									</div>

									<div class="form-group form-group--styled relative  text-14 leading-14 body-text">
										<label for="phone" class="text-14 leading-14 body-text absolute top-0 transform translate-y-sm left-0 text-black">Phone number</label>
										<input type="phone" id="phone" name="fields[Phonenumber]">
									</div>
									<div class="form-group form-group--styled relative  text-14 leading-14 body-text">
										<label for="contact-name" class="text-14 leading-14 body-text absolute top-0 transform translate-y-sm left-0 text-black">Email address</label>
										<input type="email" id="email" name="email">
									</div>

									<div class="mt-5">
										<label class="checkbox-container">I consent to be added to the email newsletter database for this project and contacted by our Real Estate agent
									  <input type="checkbox">
									  <span class="checkmark"></span>
									</label>
									</div>
								</form>
							</div>

							<div class="mt-15 pt-15 md:flex border-t-1 border-black">
								<div class="body-text mb-15 md:mb-0 md:mr-30 text-14 leading-14">
									The Residences at Karamū will be a neighbourhood full of families like yours where people feel safe, included, valued.
								</div>
								<div>
									<button @click="submit" class="w-full md:w-200 btn btn--primary text-14 leading-14">Submit</button> 
								</div>
							</div>

						</div>
					</div>

					<button class="close absolute top-25 right-25 general-hover js-close-modal text-white z-30" @click="open = false" > 

						<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line x1="0.880784" y1="21.2929" x2="20.8808" y2="1.29289" stroke="black" stroke-width="2"/>
						<line x1="1.70711" y1="1.29289" x2="21.7071" y2="21.2929" stroke="black" stroke-width="2"/>
						</svg>

					</button> 
					
				</div>
			</div>
		</transition>

		<transition name="fade-in">
			<backdrop :open="open" v-if="open" @close="open = false"/>
		</transition>
	</div>
</template>




<script>

	import Backdrop from '@vc/Backdrop';  
	import setLabelPositions from '@u/setLabelPosition';

	export default {
		name: 'RegisterModal',
		components: {
			Backdrop,
		},
		props: {
			buttonText: {
				type: String,
				required: true, 
			},

			buttonLink: {
				type: String,
				required: true,
			},
			title: {
				type: String,
				required: true,
			},
			listId: {
				type: String,
				required: true,
			},
			csrf: {
				type: String,
				required: true,
			}
		},
		watch:{
			open(oldVal, newVal){
				
				setTimeout(()=>{
					if(this.$refs.registerForm){
					 setLabelPositions(this.$refs.registerForm)
					}
				}, 300)
				
			}
		},
		data() {
			return {
				open : false,
			};
		},

		mounted() {
		},

		created() {
			
		},

		methods: {
			submit() {
				console.log('submit');
				console.log(this.$refs.registerForm);
				this.$refs.registerForm.submit()
			}
		},
	};
</script>

