import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import Vue from 'vue';
import Vuex from 'vuex';

// import {
// 	scrollTo,
// 	stopScroll,
// 	resumeScroll,
// } from './utilities/scrollControl';  s 
 

// VUE
import RegisterModal from '@vc/registerModal'; 

import { notificationCookie } from './modules/notificationCookie.js'
import { expandCollapse } from './modules/expand-collapse';
// import { dropdown } from './modules/dropdown';
import { Modal } from './modules/modal';
import { labelSlide } from './modules/label-slide.js'; 
import { navTrigger } from '@m/nav/navTrigger';
import { hideShow } from './modules/hideShow'; 
import { zoomImage } from './modules/zoomImage';
import { fixedNavFooter } from '@m/fixedNavFooter';
import glideInit from './modules/glideInit';
import { masterplanMap } from '@m/masterplanMap'; 
import { locationsMap } from '@m/locationsMap'; 



 // App main 


const swup = new Swup({
  // linkSelector: '.swup-link',
  animationSelector: '[class*="swup-transition-"]', 

  plugins: [
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: false,
		}),
		new SwupScriptsPlugin({
			optin: true
		}),
	],
});



const main = async () => {
	labelSlide.init();
	navTrigger.init();
	fixedNavFooter.init();
	glideInit.init();
	expandCollapse.init(); 
	hideShow.init();
	zoomImage.init();
	new Modal();
	masterplanMap.initMap2();
	locationsMap.initMap();  
	
	// dropdown.init();
	// // videoPlaceholder.init();
	// navTrigger.init();
	

	// const allLinks = Array.from(document.querySelectorAll('a'));
	// allLinks.forEach((link) => {
	// 	const href = link.getAttribute('href');
	// 	if (href != null) {
	// 		if (href.indexOf(window.location.host) === -1 && href[0] !== '/' && 	!window.location.host.includes('nougly')) {
	// 			link.setAttribute('target', '_blank');
	// 		}
	// 		if (href.indexOf('products') > -1 || href.indexOf('account') > -1 || href.indexOf('contact') > -1) {
	// 			link.setAttribute('data-no-swup', true);
	// 		}
	// 	}
	// });


	class InitVueComponent {
		constructor(selector, component) {
			this.selector = selector;
			this.components = component;
			this.instance = undefined;
			this.initialize();
		}

		initialize() {
			const component = this.components;
			let vueSelector;
			let vueEl;
			if (this.selector.includes('#')) {
				vueEl = document.querySelector(this.selector);
				if (vueEl) {
					this.instance = new Vue({
						el: this.selector,
						components: component,
					});
					// console.log(this.selector, this.instance);
				}
			} else {

				vueEl = document.querySelectorAll(this.selector);
				if (vueEl) {
					this.instance = [];
					vueEl.forEach((el) => {
						this.instance.push(new Vue({
							el,
							components: component,
						}));
					});
				}
			}
		}
	}
	const VueRegisterModal = new InitVueComponent('.register-modal', { RegisterModal });
};



window.addEventListener("load", onLoadFunction);

function onLoadFunction(e){

onResizeFunction();//  

window.addEventListener("resize", onResizeFunction);
}

function onResizeFunction (e){
	let vh = window.innerHeight * 0.01; 
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}






const onceOnly = () => {
	notificationCookie.init()
}

// const initialiseVueComponents = ()=>{

// };

//  Recharge implementation
//
// Check if jQuery is added, if not, then we'll loadScript, otherwise, run reChargeJS

// Execute async function
onceOnly();
// initProductInformation()
main();
// initialiseVueComponents();

swup.on('contentReplaced', () => {
	// initProductInformation()
	main();
});
