import Velocity from 'velocity-animate'; 


import { stopScroll, resumeScroll, pauseScroll } from '../utilities/scrollControl.js';
import { debounce } from '../utilities/debounce.js';

const expandCollapse = (function(){


	let expanders;

	const init = function(){
		_getElements();
		_initEvents();

	};
	const _getElements = function(){

		expanders = Array.from(document.querySelectorAll('.expand-collapse__item'));


	}
	const _initEvents = function(){ 
		expanders.forEach( expander => {
			let $toggle = expander.querySelector('.expand-collapse__toggle');
			let $body = expander.querySelector('.expand-collapse__expander');
			let $icon = $toggle.querySelector('i');
			$toggle.addEventListener('click', () => { 
				event.preventDefault();
				_toggleOpen($body, $icon) ;
			});
		});
	};

	const _toggleOpen = function($el, $button){
		console.log('toggle open');
	
		let $state = $el.getAttribute('aria-expanded');
		let options = {
			duration: 350,
			easing: 'ease-out',
		};

		if( $state == 'true' ){
			$el.setAttribute('aria-expanded', 'false')
			Velocity($el , "slideUp", options)
			$button.classList.add('md:opacity-0')
			$button.classList.remove('md:opacity-100');


		} else {
			let siblings =  $el.closest('.expanders-container').querySelectorAll('.expand-collapse');


			Velocity($el, "slideDown", options)
			$el.setAttribute('aria-expanded', 'true');
			$button.classList.remove('md:opacity-0')
			$button.classList.add('md:opacity-100');

			siblings.forEach( item => {
				let $sibIcon = item.querySelector('i')
				let $sibExpander = item.querySelector('.expand-collapse__expander')
				if ( $sibExpander !== $el ) {
					Velocity($sibExpander , "slideUp", options);
					$sibExpander.setAttribute('aria-expanded', 'false');
					// 
					$sibIcon.classList.add('md:opacity-0')
					$sibIcon.classList.remove('md:opacity-100');
				}
			});
		}

		if( $el.getAttribute('data-expander-image') ){

			_imageToggler($el);

		}


	};

	const _imageToggler = function($el) {
		let target = $el.getAttribute('data-expander-image');
		let $state = $el.getAttribute('aria-expanded');
		let $img;
		let $imgs = Array.from($el.closest('.expander-container').querySelector('.expander-container__image-group').querySelectorAll('[data-expander-image]'));
		let $default = $el.closest('.expander-container').querySelector('[data-expander-image]');


		if( target != undefined && target != null ){
			$img = $el.closest('.expander-container').querySelector('[data-expander-image="'+ target +'"]');

		} else{
			$img = $default;
		}

		if( $state == 'true'){	
			$imgs.forEach( sibling =>{
				if ($img !== sibling){
					sibling.classList.remove('md:opacity-100');
					sibling.classList.add('md:opacity-0');
				}
			});
			$img.classList.add('md:opacity-100');
			$img.classList.remove('md:opacity-0');

		} else {
			$img.classList.remove('md:opacity-100');
			$img.classList.add('md:opacity-0');

			$default.classList.add('md:opacity-100');
			$default.classList.remove('md:opacity-0');
		}


	};


	return {
		init : init
	};



})();

export { expandCollapse };