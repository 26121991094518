
const locationsMap = (function(){


  const initMap = () =>{

    const mapElement = document.getElementById('googleMap');

     const markersElement = document.querySelectorAll('.markers');
     const googlemap = { lat: -43.52995441179517, lng: 172.5296163317668 };

    if(mapElement != null ){
        
        const map = new google.maps.Map(document.getElementById("googleMap"), { 
            
          zoom: 14,
          center: googlemap, 
          disableDefaultUI: true,
          styles:
          [
        { 
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
        
                {
                    "color": "#1D1D16"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
                
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#7c7d5d"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#7c7d5d"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#1E1F18"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
                 
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#5B5C45"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#5B5C45"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#6C6C51"
                }
            ]
        }
    ],
        }); 


        markersElement.forEach((marker,index) => { 

            const markerLat = marker.getAttribute('data-lat');
            const markerLng = marker.getAttribute('data-lng');

            if (markerLat && markerLng) {
                const markerPos = new google.maps.LatLng(markerLat, markerLng);
                const marker = new google.maps.Marker({
                    position: markerPos,
                    map: map,
                    draggable: false,
                    icon : "/images/map-marker.svg",
                    labelAnchor: new google.maps.Point(18, 12),
                    label: {
                        text: index ++ === 0 ? '1' : String(index),
                        color: "black",
                        fontFamily: index === 0 ? 'bananagrotesk' : 'bananagrotesk',
                        fontSize: index === 0 ? '12px' : '12px',
                    }
                });
            }

            
        })
    }

  };
  return {
   initMap: initMap 
 }

})();

export { locationsMap }; 





