const labelSlide = ( function(){

    const init = function(){
        let inputs = Array.from(document.querySelectorAll('input'));
        // let textareas = Array.from(document.querySelectorAll('textarea'));

        // inputs = inputs.concat(textareas);

        inputs.forEach( input => {
            input.addEventListener('focus', () => {
                let parent = input.parentElement;
                if (parent.classList.contains('form-group')) {
                    parent.classList.add('active');
                }
            });
            input.addEventListener('blur', () => {
                if (input.value === '') {
                    let parent = input.parentElement;
                    if (parent.classList.contains("form-group")) { 
                        parent.classList.remove("active");
                    }
                }
            }); 
            // Default state of input and label on page load
            if (input.value !== '') {
                let parent = input.parentElement;
                if (parent.classList.contains("form-group")) {
                    parent.classList.add("active");
                }
            }
        })

    }
    return {
        init: init
    }

})();

export { labelSlide  };