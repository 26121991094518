
const notificationCookie = (()=> {

  let $notificationItems;
  let notificationId;
  let timeout;
  const oneday = 1

  const init = () =>{ 

    $notificationItems = document.querySelectorAll('.js-notification');
    $notificationItems.forEach( ($notificationItem) => {

      let $close = $notificationItem.querySelector('.js-close');
      timeout = $notificationItem.getAttribute('data-timeout');

      if( checkNotificationStatus($notificationItem) == false ){
        let timeIn;
        if ($notificationItem.getAttribute('data-timein') != null && $notificationItem.getAttribute('data-timein') != undefined){
          timeIn = $notificationItem.getAttribute('data-timein')
        } else {
          timeIn = 750
        }

        setTimeout( () =>{ 

          if ( $notificationItem.classList.contains('js-notification--bar')){
            let shift = $notificationItem.offsetHeight;
            $notificationItem.style.marginTop =  0 + 'px';

            $close.addEventListener('click', ()=>{ handleCloseNotification($notificationItem) ;

              
            } );
          } else{

            let $backDrop = document.querySelector('.js-backdrop');
            if($backDrop != null ){
              $backDrop.addEventListener('click', ()=>{
                handleCloseNotification($notificationItem);
              });
            }
            
            $close.addEventListener('click', ()=>{
              handleCloseNotification($notificationItem);
 
            });

          }

        }, timeIn);



      } else{

        $notificationItem.remove();
      }
    });
  };  

  const checkNotificationStatus = ($notificationItem) => {

    notificationId = $notificationItem.getAttribute('data-notification-id' );
    let timeoutDays = timeout;
    let storage = window.sessionStorage
    let originalCookie = storage.getItem('notification-' + notificationId);
    let now = Date.now();

    if( originalCookie != null &&  originalCookie != undefined &&  parseInt(originalCookie) + parseInt(timeoutDays) > now ){

      console.log(parseInt(originalCookie) + parseInt(timeoutDays));

      console.log(  originalCookie );
      console.log(now);
      return true;

    } else{
     console.log(false);
     return false;
   }

 }

 const handleCloseNotification = ($notificationItem) =>{
  console.log('tet');
  if ( $notificationItem.classList.contains('js-notification--bar')){
    let shift = $notificationItem.offsetHeight;

    $notificationItem.style.marginTop = '-' + shift + 'px'; 
    setTimeout(  ()=>{
      $notificationItem.remove();
    } , 300 )


  }

  notificationId = $notificationItem.getAttribute('data-notification-id' );
  let timestamp =  Date.now();

  let storage = window.sessionStorage;
  storage.setItem( 'notification-' + notificationId, timestamp.toString() );

  let originalCookie = storage.getItem('notification-' + notificationId);


};

return{
  init: init,
  handleCloseNotification: handleCloseNotification
}

})();

export { notificationCookie };