

const fixedNavFooter = (function () {

	const init = () => { 

		const header = document.getElementById('main-logo');
		const footer = document.getElementById('footer');
		const scrollShow = "block";
		const scrollHide = "hidden";
		let lastScroll = 0;  

		window.addEventListener("scroll", () => {
		  const currentScroll = window.pageYOffset;
	      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 700 ) {
		        footer.classList.add(scrollShow); 
		        footer.classList.remove(scrollHide); 
		        header.classList.add(scrollHide); 
		    }else{
				footer.classList.remove(scrollShow); 
		        footer.classList.add(scrollHide); 
		        header.classList.remove(scrollHide);
		        header.classList.add(scrollShow);
		    }
		  lastScroll = currentScroll;
		});

		window.addEventListener("load", () => {
		  const currentScroll = window.pageYOffset;
	      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 700 ) {
		        footer.classList.add(scrollShow); 
		        footer.classList.remove(scrollHide); 
		        header.classList.add(scrollHide); 
		    }else{
				footer.classList.remove(scrollShow); 
		        footer.classList.add(scrollHide); 
		        header.classList.remove(scrollHide);
		        header.classList.add(scrollShow);
		    }
		  lastScroll = currentScroll;
		  
		});
	};


	return{
		init: init
	}

})();

export { fixedNavFooter };
